import React, { useContext, useEffect, useMemo } from 'react';
import AuthContext from '../../../context/AuthProvider';

import Layout from '../../organisms/Layout/Layout';
import SEO from '../../organisms/Seo/Seo';
import Container from '../../atoms/Container/Container';
import AccountPageMenu from '../../molecules/AccountPageMenu/AccountPageMenu';

import styles from './AccountPageWrapper.module.css';

const AccountPageWrapper = ({ children, metaTitle, title }) => {
  const auth = useContext(AuthContext);
  const userChecked = auth && auth.state.userChecked;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const customerId = auth && auth.state.customerId;
  const firstName = auth && 'object' in auth.state && auth.state.object.first_name;

  useEffect(() => {
    if (userChecked && !isLoggedIn && typeof window !== 'undefined') {
      window.location = '/login';
    }
  }, [isLoggedIn, userChecked]);

  const [seoTitle, mainContent] = useMemo(() => {
    if (isLoggedIn) {
      const childPage = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { customerId });
        }
        return child;
      });

      const content = (
        <div className={styles.mainContent}>
          <h6>{title}</h6>
          {childPage}
        </div>
      );
      return [`Account - ${metaTitle}`, content];
    }

    return ['Login', <div className={styles.mainContent} />];
  }, [isLoggedIn, metaTitle, title, children, customerId]);

  return (
    <Layout>
      <SEO title={seoTitle}>
        <body className="noFooterMargin" />
      </SEO>
      <Container>
        <div className={styles.accountContainer}>
          <div className={styles.sideMenu}>
            <AccountPageMenu isLoggedIn={isLoggedIn} firstName={firstName} />
          </div>
          {mainContent}
        </div>
      </Container>
    </Layout>
  );
};

export default AccountPageWrapper;
