import React, { useState, useContext, useMemo } from "react"
import { scroller } from 'react-scroll';

import AuthContext from '../../../context/AuthProvider';
import Button from "../../atoms/Button/Button"
import Checkbox from "../../atoms/Checkbox/Checkbox"
import { validateEmail } from '../../../helpers/general'
import { gorgiasApi } from '../../../helpers/gorgias';

import styles from './ReturnsForm.module.css';

require('dotenv').config();

const ReturnsForm = ({ orderDetails }) => {
    const auth = useContext(AuthContext);
    const customer = auth && 'object' in auth.state && auth.state.object;
    const [fields, setFields] = useState({
        purchaseMade: '',
        orderId: '', 
        storeName: '',
        receiptNumber: '', 
        products: '', 
        paymentMethod: '',
        returnReason: '',
        faultDescription: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });
    const [requiredFields, setRequiredFields] = useState({
        purchaseMade: false,
        orderId: false, 
        storeName: false,
        receiptNumber: false, 
        products: false, 
        paymentMethod: false,
        returnReason: false,
        faultDescription: false,
        firstName: false,
        lastName: false,
        email: false,
        phone: false
    });
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [displayValidation, setDisplayValidation] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useMemo(() => {
        if (customer) {
            setFields(f => ({...f, ...{
                firstName: customer.first_name,
                lastName: customer.last_name,
                email: customer.email,
            }}));
        }
        
        if (orderDetails) {
            setFields(f => ({...f, ...{
                purchaseMade: 'Website',
                orderId: orderDetails.id,
                paymentMethod: orderDetails.payment_method,
            }}));
        }
    }, [customer, orderDetails]);

    const validateForm = () => {

        const invalid = Object.keys(requiredFields).filter(required => {
            if (required === 'orderId' && 'purchaseMade' in fields && fields.purchaseMade === 'Store') return false;
            else if (required === 'storeName' && 'purchaseMade' in fields && fields.purchaseMade === 'Website') return false;
            else if (required === 'receiptNumber' && 'purchaseMade' in fields && fields.purchaseMade === 'Website') return false;
            else if (required === 'paymentMethod' && 'purchaseMade' in fields && fields.purchaseMade === 'Website') return false;
            else if (required === 'email') return !validateEmail(fields[required]);

            return (!(required in fields) || fields[required] === '')
        });
        if (invalid.length > 0) {
            console.warn(invalid);
            setDisplayValidation(true);
            scroller.scrollTo('returns-form', {
                duration: 800,
                delay: 0,
                offset: -200,
                smooth: 'easeInOutQuart',
            });
            return false;
        }

        return true;
    }

    const buildGorgiasMessage = (type) => {
        const nl = type === 'html' ? '<br />' : '\r\n';
        const message = `
            A return request has been made. Details below were submitted:${nl}${nl}
            ${Object.keys(fields).map(fieldKey => `${type === 'html' ? '<strong>' : ''}${fieldKey}:${type === 'html' ? '</strong>' : ''} ${fields[fieldKey]}`).join(nl)}
            ${nl}${nl}Please review and contact the customer to continue the process.
        `;

        return message;
    }

    const attemptSubmit = (e) => {
        e.preventDefault();
        const valid = validateForm();
        setIsSending(true);
        if (valid) {
            const messageHTML = buildGorgiasMessage('html', fields);
            const messageText = buildGorgiasMessage('text', fields);
            const body = {
                messages: [
                    {
                    source: {
                        to: [
                        {
                            name: process.env.GORGIAS_NAME,
                            address: process.env.GORGIAS_EMAIL
                        }
                        ],
                        from: {
                        name: `${fields.firstName} ${fields.lastName}`,
                        address: fields.email
                        }
                    },
                    body_html: messageHTML,
                    body_text: messageText,
                    subject: `ACTION: Return Request`,
                    channel: 'email',
                    from_agent: false,
                    via: 'api'
                    }
                ]
            };
            
            gorgiasApi(`tickets`, 'POST', body).then(() => {
                /* Gorgias ticket created - Add email to Klavyio list? */
                setIsSubmitted(true);
            });
        } else {
            setIsSending(false);
        }
    }

    const setFieldValue = (fieldName, fieldValue) => {
        let outputName = fieldName;
        const outputValue = fieldValue;

        if (fieldName === 'productsArray') {
            outputName = 'products';
        }

        setDisplayValidation(false);
        const newField = {};
        newField[outputName] = outputValue;
        setFields(f => ({...f, ...newField}));
        const requiredField = {};
        requiredField[outputName] = (outputName === 'email') ? validateEmail(outputValue) : outputValue !== '';
        setRequiredFields(r => ({ ...r, ...requiredField }));
    }

    const listProducts = () => {
        return orderDetails.products.map((product, productIndex) => (
            <Checkbox key={productIndex} name="productsArray" value={product.sku} label={product.name} checked={checkboxValues.indexOf(product.sku) > -1} action={(e) => setCheckboxValues(c => {
                const values = [...c];
                const index = values.indexOf(product.sku);
                if (index > -1) {
                    values.splice(index, 1);
                } else {
                    values.push(product.sku);
                }
                setFieldValue('productsArray', values.join(', '));
                return values;
            })} />
        ))
    }

    if (isSubmitted) {
        return (
            <div className={styles.thankYou}>
                <h6>Thank you</h6>
                <p>We'll be in touch with further instruction.</p>
            </div>
        )
    } else {
        return (
            <form id="returns-form" noValidate onSubmit={(e) => attemptSubmit(e)}>
                <div className={styles.fieldSection}>
                    <h6>Order details</h6>
                    <div className={styles.mobileGrid}>
                        {!orderDetails && (
                            <>
                                <div className={`formField ${styles.mobileFormField}`}>
                                    <label htmlFor="purchaseMade" className={styles.formLabel}>Where did you make this purchase?</label>
                                    {displayValidation && 'purchaseMade' in requiredFields && !requiredFields['purchaseMade'] && (
                                        <span className={styles.errorMsg}>Please select</span>
                                    )}
                                    <select name="purchaseMade" required={true} className={`${styles.formInput} ${(displayValidation && 'purchaseMade' in requiredFields && !requiredFields['purchaseMade']) ? styles.invalid : ''}`} defaultValue={fields['purchaseMade']} onBlur={(e) => setFieldValue('purchaseMade', e.target.value)}>
                                        <option value="">Select an option...</option>
                                        <option value="Website">On the website</option>
                                        <option value="Store">In a Spencil store</option>
                                    </select>
                                </div>

                                {'purchaseMade' in fields && fields.purchaseMade === 'Website' && (
                                    <div className={`formField ${styles.mobileFormField}`}>
                                        <label htmlFor="orderId" className={styles.formLabel}>Your order number</label>
                                        {displayValidation && 'orderId' in requiredFields && !requiredFields['orderId'] && (
                                            <span className={styles.errorMsg}>Please fill</span>
                                        )}
                                        <input className={`${styles.formInput} ${(displayValidation && 'orderId' in requiredFields && !requiredFields['orderId']) ? styles.invalid : ''}`} required={true} type="text" name="orderId" value={fields['orderId']} onChange={(e) => setFieldValue('orderId', e.target.value)} />
                                    </div>
                                )}

                                {'purchaseMade' in fields && fields.purchaseMade === 'Store' && (
                                    <>
                                        <div className={`formField ${styles.mobileFormField}`}>
                                            <label htmlFor="storeName" className={styles.formLabel}>Name & location of the store</label>
                                            {displayValidation && 'storeName' in requiredFields && !requiredFields['storeName'] && (
                                                <span className={styles.errorMsg}>Please fill</span>
                                            )}
                                            <input className={`${styles.formInput} ${(displayValidation && 'storeName' in requiredFields && !requiredFields['storeName']) ? styles.invalid : ''}`} required={true} type="text" name="storeName" value={fields['storeName']} onChange={(e) => setFieldValue('storeName', e.target.value)} />
                                        </div>
                                        <div className={`formField ${styles.mobileFormField}`}>
                                            <label htmlFor="receiptNumber" className={styles.formLabel}>Your receipt number</label>
                                            {displayValidation && 'receiptNumber' in requiredFields && !requiredFields['receiptNumber'] && (
                                                <span className={styles.errorMsg}>Please fill</span>
                                            )}
                                            <input className={`${styles.formInput} ${(displayValidation && 'receiptNumber' in requiredFields && !requiredFields['receiptNumber']) ? styles.invalid : ''}`} required={true} type="text" name="receiptNumber" value={fields['receiptNumber']} onChange={(e) => setFieldValue('receiptNumber', e.target.value)} />
                                        </div>
                                        <div className={`formField ${styles.mobileFormField}`}>
                                            <label htmlFor="paymentMethod" className={styles.formLabel}>How did you pay?</label>
                                            {displayValidation && 'paymentMethod' in requiredFields && !requiredFields['paymentMethod'] && (
                                                <span className={styles.errorMsg}>Please select</span>
                                            )}
                                            <select name="paymentMethod" required={true} className={`${styles.formInput} ${(displayValidation && 'paymentMethod' in requiredFields && !requiredFields['paymentMethod']) ? styles.invalid : ''}`} defaultValue={fields['paymentMethod']} onBlur={(e) => setFieldValue('paymentMethod', e.target.value)}>
                                                <option value="">Select an option...</option>
                                                <option value="Debit Card">Debit Card</option>
                                                <option value="Credit Card">Credit Card</option>
                                                <option value="Cash">Cash</option>
                                            </select>
                                        </div>
                                    </>
                                )}

                                <div className={`formField ${styles.mobileFormField}`}>
                                    <label htmlFor="products" className={styles.formLabel}>What product(s) are you returning?</label>
                                    {displayValidation && 'products' in requiredFields && !requiredFields['products'] && (
                                        <span className={styles.errorMsg}>Please fill</span>
                                    )}
                                    <textarea className={`${styles.formInput} ${(displayValidation && 'products' in requiredFields && !requiredFields['products']) ? styles.invalid : ''}`} required={true} name="products" value={fields['products']} onChange={(e) => setFieldValue('products', e.target.value)} />
                                </div>
                            </>
                        )}

                        {orderDetails && (
                            <div className={`formField ${styles.mobileFormField}`}>
                                <label htmlFor="products" className={styles.formLabel}>What product(s) are you returning?</label>
                                {displayValidation && 'products' in requiredFields && !requiredFields['products'] && (
                                    <span className={styles.errorMsg}>Please select</span>
                                )}
                                {listProducts()}
                            </div>
                        )}

                        <div className={`formField ${styles.mobileFormField}`}>
                            <label htmlFor="returnReason" className={styles.formLabel}>What is the reason for returning the product(s)?</label>
                            {displayValidation && 'returnReason' in requiredFields && !requiredFields['returnReason'] && (
                                <span className={styles.errorMsg}>Please select</span>
                            )}
                            <select name="returnReason" required={true} className={`${styles.formInput} ${(displayValidation && 'returnReason' in requiredFields && !requiredFields['returnReason']) ? styles.invalid : ''}`} defaultValue={fields['returnReason']} onBlur={(e) => setFieldValue('returnReason', e.target.value)}>
                                <option value="">Select an option...</option>
                                <option value="Change of mind">Change of mind (30 days)</option>
                                <option value="Wrong item received">Wrong item received</option>
                                <option value="Faulty item">Faulty item</option>
                                <option value="Damaged in transit">Damaged in transit</option>
                            </select>
                        </div>

                        <div className={`formField ${styles.mobileFormField}`}>
                            <label htmlFor="faultDescription" className={styles.formLabel}>Please describe the fault</label>
                            {displayValidation && 'faultDescription' in requiredFields && !requiredFields['faultDescription'] && (
                                <span className={styles.errorMsg}>Please fill</span>
                            )}
                            <textarea className={`${styles.formInput} ${(displayValidation && 'faultDescription' in requiredFields && !requiredFields['faultDescription']) ? styles.invalid : ''}`} required={true} name="faultDescription" value={fields['faultDescription']} onChange={(e) => setFieldValue('faultDescription', e.target.value)} />
                        </div>
                    </div>
                </div>

                <div className={styles.fieldSection}>
                    <h6>Contact details</h6>
                    <div className={styles.mobileGrid}>
                        {!customer && (
                            <>
                                <div className={`formField ${styles.mobileFormField}`}>
                                    <label htmlFor="firstName" className={styles.formLabel}>First Name</label>
                                    {displayValidation && 'firstName' in requiredFields && !requiredFields['firstName'] && (
                                        <span className={styles.errorMsg}>Please fill</span>
                                    )}
                                    <input className={`${styles.formInput} ${(displayValidation && 'firstName' in requiredFields && !requiredFields['firstName']) ? styles.invalid : ''}`} required={true} type="text" name="firstName" value={fields['firstName']} onChange={(e) => setFieldValue('firstName', e.target.value)} />
                                </div>

                                <div className={`formField ${styles.mobileFormField}`}>
                                    <label htmlFor="lastName" className={styles.formLabel}>Last Name</label>
                                    {displayValidation && 'lastName' in requiredFields && !requiredFields['lastName'] && (
                                        <span className={styles.errorMsg}>Please fill</span>
                                    )}
                                    <input className={`${styles.formInput} ${(displayValidation && 'lastName' in requiredFields && !requiredFields['lastName']) ? styles.invalid : ''}`} required={true} type="text" name="lastName" value={fields['lastName']} onChange={(e) => setFieldValue('lastName', e.target.value)} />
                                </div>

                                <div className={`formField ${styles.mobileFormField}`}>
                                    <label htmlFor="email" className={styles.formLabel}>Email address</label>
                                    {displayValidation && 'email' in requiredFields && !requiredFields['email'] && (
                                        <span className={styles.errorMsg}>Please fill</span>
                                    )}
                                    <input className={`${styles.formInput} ${(displayValidation && 'email' in requiredFields && !requiredFields['email']) ? styles.invalid : ''}`} required={true} type="email" name="email" value={fields['email']} onChange={(e) => setFieldValue('email', e.target.value)} />
                                </div>
                            </>
                        )}

                        <div className={`formField ${styles.mobileFormField}`}>
                            <label htmlFor="phone" className={styles.formLabel}>Preferred contact number</label>
                            {displayValidation && 'phone' in requiredFields && !requiredFields['phone'] && (
                                <span className={styles.errorMsg}>Please fill</span>
                            )}
                            <input className={`${styles.formInput} ${(displayValidation && 'phone' in requiredFields && !requiredFields['phone']) ? styles.invalid : ''}`} required={true} type="text" name="phone" value={fields['phone']} onChange={(e) => setFieldValue('phone', e.target.value)} />
                        </div>
                    </div>
                </div>
                
                <div className={styles.fieldSection}>
                    <div className="formField">
                        <Button className={styles.formSubmit} level="primary" type="buttonSubmit" disabled={isSending}>{isSending ? 'Sending...' : 'Submit'}</Button>
                    </div>
                </div>
            </form>
        );
    }
};

export default ReturnsForm;
